import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: '#08c7fb',
        primary: '#97c81f',
        dark: '#4d4d4d',
        anchor: '#08c7fb',
        success: '#8bdf55'
      },
    },
    options: { customProperties: true },
  },
});
