import firebase from 'firebase'
import db from '@/firebase/init'
import { omit } from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  lists: [],
  data: {},
  status: {
    adding: false,
    deleting: null,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  addToList(state, payload) {
    if (!state.lists.find(l => l.id == payload.id))
      state.lists.push(payload)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setList(state, payload) {
    state.lists = []

    if (payload.size) {
      payload.forEach(doc => {
        let list = doc.data()
        list.ref = doc.ref
        list.id = doc.id
        state.lists.push(list)
      })
    }
  },

  removeList(state, payload) {
    state.lists.splice(state.lists.indexOf(payload), 1)
  },

  setData(state, payload) {
    state.data = payload
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * ADD LIST
   *
   * @PARAMS {text}
   * @RETURN void
   *----------------------------------------------------------------------------*/
  async addList({ state, commit, dispatch }, recipe) {
    commit('updateStatus', { adding: true })

    let task = recipe.ref.collection('todos')
    let data = state.data
    let text = state.data.text
    data.created = firebase.firestore.Timestamp.now()
    data.done = false
    task = data.id ? task.doc(data.id) : task.doc()
    
    await task.set(omit(data, ['ref', 'id']))
    .then(() => {
      data.ref = task
      data.id = task.id
      commit('addToList', data)
      commit('updateStatus', { adding: false })

      // GET THE USER WHO CREATED THE RECIPE
      db.collection('users')
        .doc(recipe.user)
        .get()
        .then(doc => {
          if(doc.exists) {
            const { role, email } = doc.data()
                        // Send an email if he/she is a Dietitian
            if(role === 'dietitian' || role === 'naturopath' || role === 'nutritionist') {
              let data = {
                recipient: email,
                subject: 'Comment Added',
                message: `Thanks for adding your recipe, we have reviewed your recipe and have added a comment for your review. The comment is: <br/> <br/> "${text}"`
              }

              dispatch('mail/notify', data, { root: true })
            }
          }
        })

    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updateStatus', { adding: false })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ACTION LISTS
   *
   * @PARAMS { recipe }
   * @RETURN void
   *----------------------------------------------------------------------------*/
  getList({ commit }, recipe) {
    recipe.ref
    .collection('todos')
    .get()
    .then((snapshot) => {
      commit('setList', snapshot)
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE LIST
   *
   * @PARAMS { list }
   *----------------------------------------------------------------------------*/
  deleteList({ commit, dispatch }, list) {
    commit('updateStatus', { deleting: list.id })
    console.log('delete')
    
    list.ref.delete()
    .then(() => {
      commit('removeList', list)
      commit('updateStatus', { deleting: null })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updateStatus', { deleting: null })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}