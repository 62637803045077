import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * USER REGISTRATION
   *----------------------------------------------------------------------------*/
  registration({ dispatch, rootState }, user) {
    let data = {
      to: rootState.settings.data.notificationRecipientEmail,
      template: {
        name: 'Notifications',
        data: {
          subject: 'New Mealzee User',
          message: `
            Hi ${rootState.settings.data.notificationRecipientName},<br/> <br/>
            Congratulations! User ${user.email} signed up.
          `
        }
      }
    }

    db.collection('mail')
    .add(data)
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * NOTIFY SUBSCRIBER
   * @params
   *  recipient String (email)
   *  subject String
   *  message String
   *----------------------------------------------------------------------------*/
  notify({ dispatch }, data) {
    let message = {
      to: data.recipient,
      template: {
        name: "Notifications",
        data: {
          subject: data.subject,
          message: data.message,
        },
      },
    }

    db.collection('mail')
    .add(message)
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DIETITIANS ADDED FOOD/RECIPE
   * @params
   *  recipient String (email)
   *  subject String
   *  message String
   *----------------------------------------------------------------------------*/
  added({ dispatch, rootState }, user) {
    let data = {
      to: rootState.settings.data.notificationRecipientEmail,
      template: {
        name: 'Notifications',
        data: {
          subject: `Dietitian added a ${user.mealType}`,
          message: `
            Hi ${rootState.settings.data.notificationRecipientName},<br/> <br/>
            ${user.firstName} has added a ${user.mealType} with the name "<strong>${user.mealName}</strong>".
          `
        }
      }
    }

    db.collection('mail')
    .add(data)
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * FOOD/RECIPE REPORTED
   * @params
   *  recipient String (email)
   *  subject String
   *  message String
   *----------------------------------------------------------------------------*/
  reported({ dispatch, rootState }, user) {
    let data = {
      to: rootState.settings.data.notificationRecipientEmail,
      template: {
        name: 'Notifications',
        data: {
          subject: `A ${user.mealType} has been Reported!`,
          message: `
            Hi ${rootState.settings.data.notificationRecipientName},<br/> <br/>
            The ${user.mealType} with the name "<strong>${user.mealName}</strong>" has been reported by ${user.firstName}
          `
        }
      }
    }

    db.collection('mail')
    .add(data)
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * WELCOME MESSAGE
   * @params
   *  recipient String (email)
   *----------------------------------------------------------------------------*/
  welcome({ dispatch }, user) {

    const msg1 = {
      subject: 'Welcome to MEALZEE!',
      message: `
        Hi ${user.firstName},<br/> <br/>
        Congratulations! Your membership for MEALZEE has been approved. Check out a sample meal plan by clicking this <a href="https://www.mealzee.com/sample-download" target="_blank">link</a>. <br/> <br/>
        Keen to get started? No problem, click <a href="https://app.mealzee.com/login" target="_blank">here</a> to log in to MEALZEE and help your clients achieve amazing results. <br/> <br/>
      `
    }

    const msg2 = {
      subject: 'Welcome to MEALZEE!',
      message: `
        Hi ${user.firstName},<br/> <br/>
        Congratulations! Your membership for MEALZEE has been approved. Check out a sample meal plan by clicking this <a href="https://www.mealzee.com/sample-download" target="_blank">link</a>. <br/> <br/>
        Keen to get started? No problem, click <a href="https://app.mealzee.com/login" target="_blank">here</a> to log in to MEALZEE and start saving time with meal planning. Choose from thousands of dietitian-created recipes to fuel your body with the nutrients it needs to perform. <br/> <br/>
      `
    }

    let data = {
      to: user.email,
      template: {
        name: 'Notifications',
        data: user.role != 'member' ? msg1 : msg2
      }
    }

    db.collection('mail')
    .add(data)
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

}

export default {
  namespaced: true,
  actions
}