import Vue from 'vue'
import { pick } from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  user: null,
  password: {},
  changeEmail: {},
  drawer: true,
  status: {
    error: null,
    updating: false,
    photoError: null,
    uploadProgress: 0,
    showDialog: false,
    passwordError: null,
    updatingEmail: false,
    uploadingPhoto: false,
    updateEmailError: null,
    showEmailPopuup: false,
    updatingPassword: false,
    sendingVerification: false,
  },
  data: {
    photo: null,
  },
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  mealPlans: (state) => {
    return(state.user?.mealSlots)
  },
  hasRequestTokens: (state) => {
    return state.user?.requestTokens ? true : false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  setUser(state, payload) {
    try {
      let data = payload.data()
      let userdata = firebase.auth().currentUser
      data.id = payload.id
      data.ref = payload.ref
      data.userdata = userdata
      state.user = data
    }
    catch (e) {
      state.user = payload
    }
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  setUpdatingEmailStatus(state, bol) {
    state.status.updatingEmail = bol
  },

  setUpdateEmailError(state, message) {
    state.status.updateEmailError = message
  },

  changeEmail(state, email) {
    state.user.email = email
  },

  clearChangeEmailPassword(state) {
    state.changeEmail.password = null
  },

  setEmailPopup(state, bol) {
    state.status.showEmailPopuup = bol

    if (!bol) {
      state.status.updateEmailError = null
    }
  },

  setVerifyEmailState(state, bol) {
    state.status.sendingVerification = bol
  },

  setUpdatingPasswordState(state, bol) {
    state.status.updatingPassword = bol
  },

  setPasswordError(state, message) {
    state.status.passwordError = message
  },

  setUploadingPhoto(state, bol) {
    state.status.uploadingPhoto = bol
  },

  setUploadProgress(state, progress) {
    state.status.uploadProgress = progress
  },

  setUploadError(state, message) {
    state.status.photoError = message
  },

  toggleShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  resetUploadState(state) {
    state.status.uploadProgress = 0
    state.data.photo = null
  },

  updateUserPhotoName(state, name) {
    state.user.photo = name
  },

  updateField(state, payload) {
    Vue.set(state.user, payload.field, payload.value)
  },

  updateDrawer(state, payload) {
    state.drawer = payload ? payload : !state.drawer
  },

  addSavedRecipe(state, recipe) {
    state.user.savedRecipes = state.user.savedRecipes || []
    state.user.savedRecipes.push(recipe)
  },

  removeSavedRecipe(state, recipe) {
    state.user.savedRecipes = state.user.savedRecipes.filter(r => r != recipe)
  }

}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {

  /*------------------------------------------------------------------------------
  * GET USER DATA
  *----------------------------------------------------------------------------*/
  getUserData({ commit, dispatch }) {
    let user = firebase.auth().currentUser

    return new Promise((resolve, reject) => {
      db.collection('users')
        .doc(user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            commit('setUser', doc)
            resolve(doc)
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },

  /*------------------------------------------------------------------------------
  * GET USER DATA FROM ID
  *----------------------------------------------------------------------------*/
  getUserDataFromId({ dispatch }, uid) {
    return new Promise((resolve, reject) => {
      db.collection('users')
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            try {
              let data = doc.data()
              data.id = doc.id
              data.ref = doc.ref
              resolve(data)
            }
            catch (e) {
              resolve(doc)
            }
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE USER DATA
   *----------------------------------------------------------------------------*/
  updateUserData({ commit, state, dispatch }) {
    commit('setUpdatingState', true)

    let data = Object.assign({}, pick(state.user, [
      'recipeplandescription',
      'hideinstructions',
      'allowGenerate',
      'energyUnit',
      'instagram',
      'firstName',
      'birthDate',
      'facebook',
      'lastName',
      'reg_num',
      'website',
      'gender',
      'phone',
      'gym',
    ]))

    state.user.ref
      .update(data)
      .then(() => {
        commit('setUpdatingState', false)
        dispatch('showSuccess', 'Profile successfully updated.', { root: true })
      })
      .catch(error => {
        commit('setUpdatingState', false)
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE EMAIL
   *----------------------------------------------------------------------------*/
  updateEmail({ state, commit, dispatch }) {
    let credential = firebase.auth.EmailAuthProvider.credential(state.user.userdata.email, state.changeEmail.password)
    let user = firebase.auth().currentUser
    if (state.status.updateEmailError) commit('setUpdateEmailError', null)
    commit('setUpdatingEmailStatus', true)

    user.reauthenticateWithCredential(credential)
      .then(() => {
        user.updateEmail(state.changeEmail.newEmail)
          .then(() => {

            state.user.ref.update({ email: state.changeEmail.newEmail })
              .then(() => {
                commit('changeEmail', state.changeEmail.newEmail)
                commit('setUpdatingEmailStatus', false)
                commit('setEmailPopup', false)
                dispatch('showSuccess', 'Email successfully updated.', { root: true })
              })

          })
      })
      .catch(error => {
        commit('setUpdateEmailError', error.message)
        commit('setUpdatingEmailStatus', false)
        commit('clearChangeEmailPassword')
      })

  },

  /*------------------------------------------------------------------------------
   * SEND EMAIL VERIFICATION
   *----------------------------------------------------------------------------*/
  sendVerifyEmail({ commit, state, dispatch }) {
    commit('setVerifyEmailState', true)
    if (state.status.error) commit('setError', null)

    let user = firebase.auth().currentUser

    user.sendEmailVerification()
      .then(() => {
        commit('setVerifyEmailState', false)
        dispatch('showSuccess', 'Verification email sent.', { root: true })
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PASSWORD
   *----------------------------------------------------------------------------*/
  async updatePassword({ commit, state, dispatch }) {
    commit('setUpdatingPasswordState', true)
    if (state.status.passwordError) commit('setPasswordError', null)
    let credential = firebase.auth.EmailAuthProvider.credential(state.user.userdata.email, state.password.current)
    let user = firebase.auth().currentUser

    await user.reauthenticateWithCredential(credential)
      .then(() => {
        return user.updatePassword(state.password.new).then(() => {
          dispatch('showSuccess', 'Password successfully updated', { root: true })
          commit('setUpdatingPasswordState', false)
        })
      })
      .catch(error => {
        commit('setPasswordError', error.message)
        commit('setUpdatingPasswordState', false)
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD PHOTO
   *----------------------------------------------------------------------------*/
  async uploadPhoto({ commit, state, dispatch }) {
    commit('setUploadingPhoto', true)
    var storageRef = firebase.storage().ref()
    let name = `${Date.now()}_${state.data.photo.name}`

    var metadata = {
      contentType: state.data.photo.type
    }

    var uploadTask = storageRef.child(`profilephotos/${name}`).put(state.data.photo, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('setUploadProgress', progress)
    }, error => {
      commit('setUploadError', error.message)
    }, () => {
      commit('setUploadingPhoto', false)

      if (state.user.photo) {
        storageRef.child(`profilephotos/${state.user.photo}`).delete().catch(error => console.log(error.message))
        storageRef.child(`profilephotos/thumb_${state.user.photo}`).delete().catch(error => console.log(error.message))
        storageRef.child(`profilephotos/medium_${state.user.photo}`).delete().catch(error => console.log(error.message))
        storageRef.child(`profilephotos/large_${state.user.photo}`).delete().catch(error => console.log(error.message))
      }

      state.user.ref.update({
        photo: name
      })
        .then(() => {
          commit('toggleShowDialog', false)
          dispatch('showSuccess', 'Profile photo successfully updated.', { root: true })
          commit('resetUploadState')
          commit('updateUserPhotoName', name)
        })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TAGS
   *----------------------------------------------------------------------------*/
  updateTags({ state, dispatch }) {
    state.user.ref
      .update(pick(state.user, ['mealTags', 'nutritionTags', 'ingredientTags']))
      .then(() => {
        dispatch('showSuccess', 'Tags updated', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * CHECK PAYMENTS
   *----------------------------------------------------------------------------*/
  async checkPayments({ state, dispatch, rootState }, { payments, subscription }) {

    for (var pay of payments.docs) {
      let user

      await state.user.ref
        .get()
        .then(doc => {
          if (doc.exists) {
            user = doc.data()
            user.ref = doc.ref
            user.id = doc.id
          }
        })
        .catch(error => console.log(error.message))

      let payment = pay.data()
      payment.id = pay.id
      payment.ref = pay.ref
      payment.metadata.plan = subscription.metadata.plan

      if (!user.payments || !user.payments.includes(payment.id)) {
        let plan = rootState.pricing.plans.find(p => p.id == payment.metadata.plan)

        if (plan) {
          user.payments ? user.payments.push(payment.id) : user.payments = [payment.id]

          let data = {
            downloadCredits: plan.downloads > 0 ? firebase.firestore.FieldValue.increment(parseInt(plan.downloads)) : -1,
            mealSlots: parseInt(plan.meals),
            payments: user.payments
          }

          await user.ref
            .update(data)
            .catch(error => {
              console.log(error.message)
            })
        }
      }
    }

    if(!state.user)
      dispatch('getUserData')
  },

  /*------------------------------------------------------------------------------
   * CHECK PAYMENTS
   *----------------------------------------------------------------------------*/
  async checkTrialingPayments({ state, dispatch }, { subscription }) {
    // Check if the plan subscribe to has a free trial
    let data = {
      downloadCredits: -1,
      mealSlots: -1,
      freeTrial: true,
      expiredPopUp: true,
      trial_end: subscription.trial_end
    }
    let user

    await state.user.ref
      .get()
      .then(doc => {
        if (doc.exists) {
          user = doc.data()
          user.ref = doc.ref
          user.id = doc.id
        }
      })
      .catch(error => console.log(error.message))
    await user.ref
      .update(data)
      .catch(error => {
        console.log(error.message)
      })

    if(!state.user)
      dispatch('getUserData')
  },

  /*------------------------------------------------------------------------------
   * UPDATE USER FIELD
   * Object
   *  field String
   *  value Any
   *  silent Boolean (optional)
   *  message String (optional)
   *----------------------------------------------------------------------------*/
  async updateUserField({ state, commit, dispatch }, data) {
    if (state.user) {
      await state.user.ref
        .update({ [data.field]: data.value })
        .then(() => {
          commit('updateField', data)
          if (!data.silent) dispatch('showSuccess', data.message || 'User field updated', { root: true })
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
        })
    }
  },

  async updateUserFieldFromForm({ state, commit, dispatch }, data) {
    if (data.user) {
      await state.user.ref
        .update({ [data.field]: data.value })
        .then(() => {
          commit('updateField', data)
          if (!data.silent) dispatch('showSuccess', data.message || 'User field updated', { root: true })
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
        })
    }
  },

  /*------------------------------------------------------------------------------
   * TOGGLE DRAWER 
   * Boolean
   *----------------------------------------------------------------------------*/

  toggleDrawer({ commit }, { drawer }) {
    commit('updateDrawer', drawer)
  },

  /*------------------------------------------------------------------------------
   * SAVE RECIPE
   * @param
   *  user Object
   *  recipe ID 
   ----------------------------------------------------------------------------*/
  async saveRecipe({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      data.user.ref
        .update({ savedRecipes: firebase.firestore.FieldValue.arrayUnion(data.recipe.id) })
        .then(() => {
          commit('addSavedRecipe', data.recipe.id)
          dispatch('showSuccess', 'Recipe Save', { root: true })
          resolve()
        })
        .catch(error => {
          console.log(error.message)
          reject()
        })
    })
  },

  /*------------------------------------------------------------------------------
   * UNSAVE RECIPE
   * @param
   *  user Object
   *  recipe ID 
   ----------------------------------------------------------------------------*/
  async unsaveRecipe({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      data.user.ref
        .update({ savedRecipes: firebase.firestore.FieldValue.arrayRemove(data.recipe.id) })
        .then(() => {
          commit('removeSavedRecipe', data.recipe.id)
          dispatch('showSuccess', 'Recipe Unsaved', { root: true })
          resolve()
        })
        .catch(error => {
          console.log(error.message)
          reject()
        })
    })
  },

  /*------------------------------------------------------------------------------
  * GET USER INFO BY ID
  *----------------------------------------------------------------------------*/
  getUserInfo({ commit, dispatch }, user) {

    return new Promise((resolve, reject) => {
      db.collection('users')
        .doc(user)
        .get()
        .then(doc => {
          if (doc.exists) {
            commit('setUser', doc)
            resolve(doc)
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
