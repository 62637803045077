import Vue from 'vue';
import db from '@/firebase/init';

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
  return {
    organisations: [],
    data: {},
    status: {
      error: null,
      adding: null,
      getting: null,
      removing: null,
    },
  };
};

const state = initialState();
/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/

const getters = {
  getOrg: (state) => id => {
    let org = state.organisations.find(o => o.id == id)
    return org
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  gettingState(state, bol) {
    state.status.getting = bol;
  },
  addingState(state, bol) {
    state.status.adding = bol;
  },
  setError(state, message) {
    state.status.error = message;
  },
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0];
  },
  removingState(state, bol) {
    state.status.removing = bol;
  },
  setOrganisations(state, payload) {
    state.organisations = [];

    payload.forEach((org) => {
      let data = Vue.prototype.$formatData(org);
      state.organisations.push(data);
    });
  },
  insertOrganisation(state, payload) {
    let data;

    try {
      data = Vue.prototype.$formaData(payload);
    } catch {
      data = payload;
    }

    if (!state.organisations.find((o) => o.id == data.id))
      state.organisations.push(data);
  },

  removeFromOrgs(state, org) {
    state.organisations = state.organisations.filter((o) => o.id !== org);
  },
};

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  async getAllOrganisations({ commit, dispatch }) {
    commit('gettingState', true);

    let query = db.collection('organisations');

    await query
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setOrganisations', snapshot);
          commit('gettingState', false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        commit('gettingState', false);
        dispatch('showError', error.message, { root: true });
      });
  },
  async addOrganisation({ state, commit, dispatch }, organisation) {
    commit('addingState', true);

    if (state.status.error) commit('setError', null);
    let hasError = false;

    let data = organisation;
    data.updateAt = Date.now();


    await db
      .collection('organisations')
      .add(data)
      .then(() => {
        commit('addingState', false);

        commit('insertOrganisation', { ...data, id: Date.now()})

        dispatch('showSuccess', 'Organisation has been added', { root: true });
      })
      .catch((error) => {
        commit('addingState', false);
        commit('setError', error.message);
        hasError = true;
      });

    return hasError;
  },

  async removeOrg({ commit, dispatch }, org) {
    commit('removingState', true);

    await org.ref
      .delete()
      .then(() => {
        commit('removeFromOrgs', org);
        commit('removingState', false);
        dispatch('showSuccess', 'Organisation successfully deleted.', {
          root: true,
        });
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
        commit('removeState', false);
        console.log(error.message);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
