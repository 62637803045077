import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'
import firebase, { firestore } from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
  return {
    goal: null,
    data: {
      mainMeals: [],
      customTarget: 0,
      snacks: 0
    },
    status: {
      creating: false,
      getting: true,
    }
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  // BMI
  bmi: (state) => {
    let bmi = 0

    if (state.data.height && state.data.weight) {
      let weight = parseFloat(state.data.weight)
      let height = parseFloat(state.data.height)
      bmi = weight / ((height/100) * (height/100))
    }

    return parseFloat(bmi.toFixed(1))
  },

  // RMR Calories
  rmrCalories: (state, getters) => {
    let value = 0

    if (state.data.weight && state.data.height && getters.age) {
      let weight = parseInt(state.data.weight)
      let height = parseInt(state.data.height)
      let age = parseInt(getters.age)
      value = ((9.99 * weight) + (6.25 * height) - (4.92 * age) + 5)
    }

    return Math.round(value)
  },

  // RMR Kilojoules
  rmrKj: (state, getters) => {
    let value = 0

    if (getters.rmrCalories) {
      value = getters.rmrCalories * 4.18
    }

    return Math.round(value)
  },

  // IDEAL BODY WEIGHT
  idealWeight: (state) => {
    let data = 0
    
    if (state.data.height) {
      let wt = (parseFloat(state.data.height) / 100) * (parseFloat(state.data.height) / 100) * 25
      data = parseFloat(wt.toFixed(1))
    }

    return data
  },

  // Estimated Energy Requirement to MAINTAIN Weight (Calories)
  eermwCal: (state, getters, rootState) => {
    var value = 0
    let user = rootState.user.user
    let gender = user.role == 'member' ? user.gender : state.data.gender

    if (state.data && getters.age && user) {
      let weight = parseInt(state.data.weight)
      let height = parseInt(state.data.height)
      let age = parseInt(getters.age)
      if (state.data.physicalActivityLevel && gender == 'male') value = ((9.99 * weight)+(6.25 * height)-(4.92 * age)+5) * state.data.physicalActivityLevel.male
      else if (state.data.physicalActivityLevel && gender == 'female') value = ((9.99 * weight)+(6.25 * height)-(4.92 * age) - 161) * state.data.physicalActivityLevel.female
    }

    return Math.round(value)
  },
  
  // Estimated Energy Requirement to MAINTAIN Weight (Kilojoules)
  eermwKj: (state, getters) => {
    var value = 0

    if (getters.eermwCal) {
      value = getters.eermwCal * 4.18
    }

    return Math.round(value)
  },

  // Estimated Energy Requirement to LOSE Weight (Kilojoules)
  eerlwKj: (state, getters) => {
    var value = 0

    if (getters.bmi >= 50) value = getters.eermwKj * 0.6
    else if (getters.bmi >= 45) value = getters.eermwKj * 0.625
    else if (getters.bmi >= 40) value = getters.eermwKj * 0.65
    else if (getters.bmi >= 35) value = getters.eermwKj * 0.675
    else if (getters.bmi >= 30) value = getters.eermwKj * 0.7
    else if (getters.bmi >= 25) value = getters.eermwKj * 0.75
    else if (getters.bmi > 25) value = getters.eermwKj * 0.75
    else if (getters.bmi < 25) value = getters.eermwKj * 1

    return Math.round(value)
  },
  
  // Estimated Energy Requirement to LOSE Weight (Calories)
  eerlwCal: (state, getters) => {
    var value = 0

    value = getters.eerlwKj / 4.18

    return Math.round(value)
  },
  
  // Estimated Energy Requirement to GAIN Weight (Calories)
  eergwCal: (state, getters) => {
    var value = 0

    if (getters.bmi <= 17) value = 'You are severely underweight. Please contact your doctor or dietitian for specialised assistance'
    else if (getters.bmi <= 18 ) value = getters.eermwCal * 1.4
    else if (getters.bmi >= 18.1 ) value = getters.eermwCal * 1.3

    return (typeof value == 'string') ? value : Math.round(value)
  },
  
  // Estimated Energy Requirement to GAIN Weight (Kilojoules)
  eergwKj: (state, getters) => {
    var value = 0

    if (getters.bmi <= 17) value = 'You are severely underweight. Please contact your doctor or dietitian for specialised assistance'
    else if (getters.bmi <= 18 ) value = getters.eermwKj * 1.4
    else if (getters.bmi >= 18.1 ) value = getters.eermwKj * 1.3

    return (typeof value == 'string') ? value : Math.round(value)
  },

  // ADJUST BODY WEIGHT
  adjusted: (state, getters) => {
    let data = 0
    
    if (state.data) {
      data = ((parseFloat(state.data.weight) - parseFloat(getters.idealWeight)) / 4) + parseFloat(getters.idealWeight)
    }

    return data
  },

  // DAILY ENERGY REQUIREMENT TOM MAINTAIN WEIGHT
  dailyEnergyRequirement: (state, getters) => {
    let energy = 0

    if (state.data) {
      if (state.data.weight )
        energy = parseFloat(getters.adjusted) * 100
    }
    
    return parseFloat(energy.toFixed(0))
  },

  // DAILY ENERGY REQUIREMENT TOM MAINTAIN WEIGHT (CAL)
  dailyEnergyRequirementCal: (state, getters) => {
    let energy = 0
    
    if (state.data) {
      if (state.data.weight)
        energy = parseFloat(getters.dailyEnergyRequirement) / 4.18
    }
    
    return parseFloat(energy.toFixed(0) )
  },

  // WEIGHT LOSS ENERGY TARGET
  weightLossEnergyTarget: (state, getters) => {
    let target = 0
    let bmi = getters.bmi
    let energy = getters.dailyEnergyRequirement

    if (state.data) {
      if (state.data.weight) {
        if (bmi >= 50) target = energy * 0.5
        else if (bmi >= 45) target = energy * 0.55
        else if (bmi >= 40) target = energy * 0.6
        else if (bmi >= 35) target = energy * 0.65
        else if (bmi >= 30) target = energy * 0.7
        else if (bmi >= 25) target = energy * 0.75
        else if (bmi < 25) target = energy * 1
      }
    }

    return target
  },

  // DAILY ENERGY REQUIREMENTS TO lOSE WEIGHT
  dailyEnergyRequirementLose: (state, getters) => {
    let energy = 0
    let bmi = getters.bmi
    let weight = getters.weightLossEnergyTarget

    if (state.data) {
      if (state.data.weight) {
        if (bmi <= 25) energy = getters.dailyEnergyRequirement
        if (weight >= 6500) energy = 7000
        if (weight >= 6000) energy = 6000
        if (weight >= 5500) energy = 6000
        if (weight >= 5000) energy = 5000
        if (weight >= 4500) energy = 5000
        if (weight >= 4000) energy = 4000
        if (weight < 4000) energy = 4000
      }
    }

    return parseFloat(energy)
  },

  // Suggested Calorie target based on your goals
  sctbg: (state, getters) => {
    let value = 0
    
    if (state.data.goal == 'g1') value = getters.eerlwCal
    else if (state.data.goal == 'g5') value = getters.eerlwCal
    else if (state.data.goal == 'g8') value = getters.eergwCal
    else if (['g2', 'g3', 'g4', 'g6', 'g7', 'g9'].includes(state.data.goal)) value = getters.eermwCal

    return value
  },
  
  // Suggested Kilojoule target based on your goals
  sktbg: (state, getters) => {
    let value = 0
    
    if (state.data.goal == 'g1') value = getters.eerlwKj
    else if (state.data.goal == 'g5') value = getters.eerlwKj
    else if (state.data.goal == 'g8') value = getters.eergwKj
    else if (['g2', 'g3', 'g4', 'g6', 'g7', 'g9'].includes(state.data.goal)) value = getters.eermwKj

    return value
  },

  // CUSTOM DAILY ENERGY REQUIREMENTS
  customDailyEnergyRequirementLose: (state, getters) => {
    let energy = 0

    if (state.data) {
      energy = parseInt(state.data.customKj) * state.data.mainMeals.length
      energy += state.data.customSnackKj ? parseInt(state.data.customSnackKj) * state.data.snacks : parseInt(getters.snacksEnergy)
    }

    return energy
  },

  // ENERGY TARGET
  energyTarget: (state, getters) => {
    let data = 0

    if (state.data) {
      data = state.data.weight ? parseFloat(getters.dailyEnergyRequirementLose) / 4.18 : 0
    }

    return data
  },

  // DAILY ENERGY REQUIREMENTS TO LOSE WEIGHT
  energyRequirementsLose: (state, getters) => {
    let data = 0

    if (state.data) {
      data = state.data.weight ? Math.round(getters.energyTarget/50) * 50 : 0
    }

    return data
  },

  // MAIN MEALS ENERGY
  mainMealsEnergy: (state, getters) => {
    let total = 0

    if (state.data) {
      let multiplier = 1
      let energy = state.data.customTarget || getters.sktbg
      let mainMeals = state.data.mainMeals
      let snacks = state.data.snacks
      let mainMealsCount = mainMeals.length

      if (mainMeals.includes('desert')) {
        snacks++
        mainMealsCount--
      }

      switch(snacks) {
        case 0: multiplier = 1; break;
        case 1: multiplier = 0.9; break;
        case 2: multiplier = 0.85; break;
        case 3: multiplier = 0.8; break;
        case 4: multiplier = 0.75; break;
      }
      
      total = (energy * multiplier) / mainMealsCount
    }

    return Math.round(parseFloat(total))
  },
  
  // SNACKS ENERGY
  snacksEnergy: (state, getters) => {
    let total = 0
    
    if (state.data) {
      let multiplier = 0
      let energy = state.data.customTarget || getters.sktbg
      let snacks = state.data.snacks
      let mainMeals = state.data.mainMeals

      if (mainMeals.includes('desert')) snacks++

      switch(snacks) {
        case 0: multiplier = 0; break;
        case 1: multiplier = 0.1; break;
        case 2: multiplier = 0.15; break;
        case 3: multiplier = 0.2; break;
        case 4: multiplier = 0.25; break;
      }
      
      total = (energy * multiplier) / snacks
    }

    return Math.round(parseInt(total))
  },

  // GET USER AGE
  age: (state, getters, rootState) => {
    let user = rootState.user.user
    let years = 0
    if (['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(user.role)) {
      years = state.data.age
    }
    else if (user && user.birthDate) {
      let birth = user.birthDate
      
      if (typeof user.birthDate == 'string') {
        birth = firestore.Timestamp.fromDate(new Date(birth))
      }
      years = moment().diff(birth.toDate(), 'years')

      console.log(years)
    }

    return years
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },

  setCreatingState(state, bol) {
    state.status.creating = bol
  },

  setGoal(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.goal = data
    state.status.getting = false
  },

  setGettingState(state, bol) {
    state.status.getting = bol
  },

  setData(state, payload) {
    let data

    try {
      data = Object.assign({},Vue.prototype.$formatData(payload))
    }
    catch {
      data = Object.assign({}, payload)
    }

    state.data = data
  },

  updateField(state, payload) {
    Vue.set(payload.goal, payload.field, payload.value)
  },

  setGoalData(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
      data = Object.assign({}, payload)
    }
    catch {
      data = Object.assign({}, payload)
      delete data.id
      delete data.ref
      delete data.recipes
      delete data.user
    }

    state.data = data
  },

  setAddNewMealplan(state, payload) {
    let data

    data = Object.assign({}, payload)
    const { mainMeals, snacks, customTarget } = data

    state.data.mainMeals = mainMeals
    state.data.snacks = snacks
    state.data.customTarget = customTarget
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE USER
   *----------------------------------------------------------------------------*/
  create({ state, commit }, id = null) {
    return new Promise((resolve, reject) => {
      commit('setCreatingState', true)
      let user = firebase.auth().currentUser
  
      state.data.createdAt = Date.now()
      state.data.updatedAt = Date.now()
      state.data.user = id || user.uid
      let data = _.omitBy(state.data, _.isUndefined)
  
      db.collection('goals').add(data)
      .then((docRef) => {
        commit('setCreatingState', false)
        let goal = data
        goal.ref = docRef
        goal.id = docRef.id
        commit('setGoal', goal)
        resolve(goal)
      })
      .catch(error => {
        console.log(error.message)
        reject(error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE USER FORM VERSION
   *----------------------------------------------------------------------------*/
  createFromForm({ state, commit }, params) {
    const { id, user, customTarget } = params
    return new Promise((resolve, reject) => {
      commit('setCreatingState', true)
  
      state.data.createdAt = Date.now()
      state.data.updatedAt = Date.now()
      state.data.user = id || user.uid
      state.data.customTarget = customTarget || 0

      let data = _.omitBy(state.data, _.isUndefined)

      db.collection('goals').add(data)
      .then((docRef) => {
        commit('setCreatingState', false)
        let goal = data
        goal.ref = docRef
        goal.id = docRef.id
        commit('setGoal', goal)
        resolve(goal)
      })
      .catch(error => {
        console.log(error.message)
        reject(error.message)
      })
    })
  },
  /*------------------------------------------------------------------------------
   * UPDATE GOAL
   *----------------------------------------------------------------------------*/
  async update({ state, commit, dispatch }, props = null) {
    commit('setCreatingState', true)
    let hasError = false

    state.data.updatedAt = Date.now()
    let data

    if(!props)
      data = _.omitBy(state.data, _.isUndefined)
    else
      data = _.omitBy(props, _.isUndefined)

    await state.data.ref.update(_.omit(data, ['id', 'ref']))
    .then(() => {
      commit('setCreatingState', false)
      commit('setGoal', { ...state.goal, ...data })

      if(props)
        dispatch('getGoal', props.user)
    })
    .catch(error => {
      console.log(error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * GET CURRENT USER GOAL
   *----------------------------------------------------------------------------*/
  getGoal({ commit, dispatch }, id = null) {
    let user = firebase.auth().currentUser
    commit('setGettingState', true)
    let count = 0

    let idToUse = id || user.uid

    db.collection('goals')
    .where('user', '==', idToUse)
    .limit(1)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setGoal', snapshot.docs[0])
        commit('setData', snapshot.docs[0])
        count = 1
      }
      else {
        commit('setGettingState', false)
        commit('setGoal', null)
        // commit('setData', {
        //   mainMeals: []
        // })
      }
    })
    .catch(error => {
      dispatch('setError', error.message, { root: true })
    })

    return count
  },

  /*------------------------------------------------------------------------------
   * DELETE GOAL
   *----------------------------------------------------------------------------*/
  async deleteGoalByUser({ dispatch }, user) {
    await db.collection('goals')
    .where('user', '==', user)
    .limit(1).get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        await doc.ref.delete()
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * CLEAR CUSTOM TARGET
   *----------------------------------------------------------------------------*/
  deleteCustomTarget({ commit, dispatch }, goal) {
    goal.ref
    .update({ customTarget: null })
    .then(() => {
      commit('updateField', {
        goal, 
        field: 'customTarget',
        value: null,
      })

      dispatch('showSuccess', 'Custom target deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
