import Vue from 'vue'
import firebase from 'firebase'
import _omit from 'lodash/omit'
import db from '@/firebase/init'
import { orderBy } from 'lodash'
import _omitBy from 'lodash/omitBy'
import _isUndefined from 'lodash/isUndefined'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  tags: [],
  data: {},
  status: {
    adding: false,
    error: null,
    firstLoad: false,
    updating: false,
    getting: false,
    deleting: false,
    updatingAll: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  tagData: (state) => (id) => {
    let tag = state.tags.find(t => t.id == id)
    return tag || {} 
  },

  tags: (state, getters, rootState) => {
    let user = rootState.user.user
    
    if (user && state.tags.length) {
      let tags = state.tags.filter(tag => tag.published)
      tags = orderBy(tags, 'name', 'asc')

      tags = tags.filter(tag => {
        if (tag.roles)
          return tag.roles.includes(user.role) || user.role == 'admin'
        else return false
      })

      return tags
    }
    else return []
  },
  checkIfUnpublish: (state) => (id) => {
    let tag = state.tags.find(t => t.id == id)

    if(!tag) return false

    return tag.published
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  addingState(state, bol) {
    state.status.adding = bol
  },

  insertTag(state, payload) {
    if (!state.tags.find(t => t.id == payload.id)) {
      state.tags.push(payload)
      state.data = {}
    }
  },

  errorState(state, message) {
    state.status.error = message
  },

  setTags(state, payload) {
    state.tags = []

    if (payload.size) {
      payload.forEach(doc => {
        if (!state.tags.find(t => t.id == doc.id)) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          state.tags.push(data)
        }
      })
    }

    state.status.firstLoad = true
    state.status.getting = false
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  updateTagData(state, payload) {
    let tag = state.tags.find(t => t.id == payload.id)
    Vue.set(state.tags, state.tags.indexOf(tag), payload)
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeTag(state, payload) {
    let tag = state.tags.find(t => t.id == payload.id)
    let index = state.tags.indexOf(tag)
    state.tags.splice(index, 1)
  },

  clearMainAutoTag(state, tag) {
    tag.main = null
    tag.mainCondition = null
    tag.mainAmount = null
  },
  
  clearSnackAutoTag(state, tag) {
    tag.snack = null
    tag.snackCondition = null
    tag.snackAmount = null
  },
  
  clearHundredAutoTag(state, tag) {
    tag.hundred = null
    tag.hundredCondition = null
    tag.hundredAmount = null
  },

  updatingAllState(state, bol) {
    state.status.updatingAll = bol
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  updateValue(state, payload) {
    Vue.set(payload.tag, payload.field, payload.value)
  },

  switchToBool(state, payload) {
    payload.tag.switchToBool = payload.val
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * ADD NEW TAG
   *----------------------------------------------------------------------------*/
  async addTag({ state, commit, dispatch }) {
    commit('addingState', true)
    commit('errorState', null)
    
    let data = state.data
    data.created = firebase.firestore.Timestamp.now()
    
    await db.collection('nutritionTags')
    .add(data).then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('insertTag', data)
      commit('addingState', false)
      dispatch('showSuccess', 'Nutrition tag added', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('errorState', error.message)
      commit('addingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET TAGS
   *----------------------------------------------------------------------------*/
  async getTags({ commit, dispatch }) {
    commit('gettingState', true)
    
    await db.collection('nutritionTags')
    .get()
    .then(snapshot => {
      commit('setTags', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TAG
   *----------------------------------------------------------------------------*/
  async updateTag({ commit, dispatch }, tag) {
    commit('updatingState', true)
    
    await tag.ref.update(_omit(tag, ['ref', 'id']))
    .then(() => {
      commit('updateTagData', tag)
      commit('updatingState', false)
      dispatch('showSuccess', 'Nutrition tag updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('errorState', error.message)
      commit('updatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE TAG
   *----------------------------------------------------------------------------*/
  async deleteTag({ commit, dispatch }, tag) {
    commit('deletingState', true)
    
    await tag.ref.delete()
    .then(() => {
      commit('removeTag', true)
      commit('deletingState', false)
      dispatch('showSuccess', 'Nutrition tag deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE ALL
   *----------------------------------------------------------------------------*/
  updateAll({ state, commit, dispatch }) {
    commit('updatingAllState', true)
    
    let batch = db.batch()
    
    state.tags.forEach(tag => {
      let data = _omit(tag, ['ref', 'id'])
      data = _omitBy(tag, _isUndefined)
      batch.set(tag.ref, data)
    })

    batch.commit()
    .then(() => {
      dispatch('showSuccess', 'Nutrition tags udpated', { root: true })
      commit('updatingAllState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TAG FIELD
   * @PARAMS
   *  tag Object
   *  value Any
   *  field String
   *  message String (optional)
   *  silent Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateTagField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.tag.ref
    .update({
      [data.field]: data.value,
      updated: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      commit('updateValue', data)
      if (!data.silent) dispatch('showSuccess', data.message || 'Tag updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
    .finally(() => {
      commit('updateStatus', { updating: false })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}