import firebase from 'firebase'
import "firebase/messaging";



const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

// firebase.functions().useFunctionsEmulator('http://localhost:5001');

export default firebaseApp.firestore()
