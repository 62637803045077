import db from '@/firebase/init'
import firebase from 'firebase'
import _ from 'lodash'

/*------------------------------------------------------------------------------
 * STATE`
 *----------------------------------------------------------------------------*/

const state = {
  data: {},
  search: {},
  portions: [],
  pendings: [],
  portion: null,
  ingredients: [],
  searchResult: [],
  lastVisible: null,
  status: {
    error: null,
    adding: false,
    approving: [],
    getting: false,
    newAdded: null,
    deleting: false,
    searching: false,
    firstPendingLoad: false,
  },
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  activeIngredients: (state) => {
    let ingredients = state.ingredients
    return _.orderBy(ingredients, ['name'], ['asc'])
  },

  ingredientData: (state) => (id) => {
    let ingredient = state.ingredients.find(i => i.id == id)
    return ingredient || {}
  },
  
  portionData: (state) => (id) => {
    let portion = state.portions.find(i => i.id == id)
    return portion || {}
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  removeIngredient(state, ingredient) {
    state.ingredients.splice(state.ingredients.indexOf(ingredient), 1)
    state.searchResult.splice(state.ingredients.indexOf(ingredient), 1)
    state.status.deleting = false
  },

  insertIngredient(state, payload) {
    if (!state.ingredients.find(ingredient => ingredient.id == payload.id)) {
      state.ingredients.push(payload)
    }
  },

  setData(state, payload) {
    state.data = payload
  },

  setPortions(state, payload) {
    state.portions = payload
    state.status.searching = false
  },

  selectPortion(state, portion) {
    state.portion = portion
  },

  setSearchingState(state, bol) {
    state.status.searching = bol
  },

  addPortion(state, payload) {
    if (!state.portions.find(p => p.id == payload.id))
      state.portions.push(payload)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET INGREDIENTS
   *----------------------------------------------------------------------------*/
  getIngredients({ state, commit }, ingredients) {
    commit('gettingState', true)

    ingredients.forEach(ing => {
      let data =  ing
      let ingredient = state.ingredients.find(i => { 
        return i.ingredient.toLowerCase() == ing.ingredient.toLowerCase() && 
               i.portion.toLowerCase() == ing.portion.toLowerCase() 
      })
      
      if (!ingredient) {
        db.collection('ingredients').doc(data.ingredient.toLowerCase())
        .collection('portions').doc(data.portion.toLowerCase())
        .get()
        .then(doc => {
          if (doc.exists) {
            data.attr = doc.data().attr
            data.unit = doc.data().unit
            data.weight = doc.data().weight
            data.ref = doc.ref
            data.id = doc.id
            commit('insertIngredient', data)
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * GET INGREDIENT
   *----------------------------------------------------------------------------*/
  getFood({ commit }, id) {
    db.collection('foods')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        let ingredient = doc.data()
        ingredient.id = doc.id
        ingredient.ref = doc.ref
        commit('insertIngredient', ingredient)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET FOODS
   *----------------------------------------------------------------------------*/
  getFoods({ commit, dispatch }, ids) {
    var promises = []

    if (ids.length) {
      ids.forEach(id => {
        promises.push(db.collection('foods').doc(id).get())
      })

      Promise.all(promises)
      .then(docs => {
        if (docs.length) {
          docs.forEach(doc => {
            if (doc.exists) {
              let ingredient = doc.data()
              ingredient.id = doc.id
              ingredient.ref = doc.ref
              commit('insertIngredient', ingredient)
              dispatch('getFoodPortions', ingredient)
            }
          })
        }
      })
    }
  },

  getFoodsAsync({ commit, dispatch }, ids) {
    return new Promise((resolve, reject) => {
      var promises = []

      if (ids.length) {
        ids.forEach(id => {
          promises.push(db.collection('foods').doc(id).get())
        })

        Promise.all(promises)
        .then(docs => {
          if (docs.length) {
            docs.forEach(doc => {
              if (doc.exists) {
                let ingredient = doc.data()
                ingredient.id = doc.id
                ingredient.ref = doc.ref
                commit('insertIngredient', ingredient)
                dispatch('getFoodPortions', ingredient)

                resolve(ingredient)
              }
            })
          }
        })
        .catch(error => {
          console.log(error.message)
          reject()
        })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * GET FOOD PORTIONS
   *----------------------------------------------------------------------------*/
  getFoodPortions({ commit }, food) {
    food.ref
    .collection('portions')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          let portion = doc.data()
          portion.id = doc.id
          portion.ref = doc.ref
          portion.food = food.name
          commit('addPortion', portion)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

 async getFoodPortionsAsync({ commit }, food) {
    return new Promise((res, rej) => {
      food.ref
      .collection('portions')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let portion = doc.data()
            portion.id = doc.id
            portion.ref = doc.ref
            portion.food = food.name
            commit('addPortion', portion)
            res(portion)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        rej()
      })
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH INGREDIENT
   *----------------------------------------------------------------------------*/
  getPortions({ commit, dispatch }, id) {
    var searchIng = firebase.functions().httpsCallable('apis-searchIngredient')
    commit('setSearchingState', true)
    let portions = []

    searchIng({ food: id })
    .then(resp => {
      console.log(resp)

      let response = resp.data
      Object.keys(response).forEach(key => {
        if (typeof response[key] == 'object') {
          let ptns = response[key].portions
          if (ptns && ptns.length !== 0) {
            Object.keys(ptns).forEach(k => {
              if (ptns[k]['name']) {
                if (!portions.find(port => port.name == ptns[k]['name'])) portions.push({ name: ptns[k]['name'], unit: ptns[k]['unit'], weight: ptns[k]['val'] })
              }
            })
          }
        }
      })

      commit('setPortions', portions)
    })
    .catch(error => {
      console.log(error)
      commit('setSearchingState', false)
      dispatch('showError', 'An error has ocurred. Please try again.', { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
