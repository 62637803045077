import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import firebase from 'firebase'
import store from '@/store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Dashboard.vue'),
        meta: { access: ['admin', 'member', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'] }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/user/Profile.vue'),
        meta: { access: ['admin', 'member', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'] }
      },
      {
        path: 'members',
        name: 'Members',
        component: () => import('../views/users/Members.vue'),
        meta: { access: ['admin'] }
      },
      {
        path: 'recipes',
        name: 'Recipes',
        component: () => import('../views/admin/Recipes.vue'),
        meta: { access: ['admin', 'dietitian', 'member', 'personal_trainer', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'my-recipes',
        name: 'MyRecipes',
        component: () => import('../views/recipe/MyRecipes.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'recipes/:id',
        name: 'EditRecipe',
        component: () => import('../views/admin/AddRecipe.vue'),
        meta: { access: ['admin', 'dietitian', 'member', 'personal_trainer', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'customize-recipes/:id',
        name: 'CustomizeRecipe',
        component: () => import('../views/admin/CustomizeRecipe.vue'),
        meta: { access: ['admin', 'dietitian', 'member', 'personal_trainer', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'add-recipe',
        name: 'AddRecipe',
        component: () => import('../views/admin/AddRecipe.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'import-recipes',
        name: 'ImportRecipes',
        component: () => import('../views/admin/ImportRecipes.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('../views/admin/Categories.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'meal-tags',
        name: 'MealTags',
        component: () => import('../views/admin/MealTags.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'nutrition-tags',
        name: 'NutritionTags',
        component: () => import('../views/admin/NutritionTags.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'ingredients',
        name: 'Ingredients',
        component: () => import('../views/admin/Ingredients.vue'),
        meta: { access: ['admin', 'dietitian', 'naturopath', 'nutritionist'] },
      },
      {
        path: 'measurements',
        name: 'Measurements',
        component: () => import('../views/admin/Measurements.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: () => import('../views/pricing/Pricing.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../views/admin/Settings.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'organisations',
        name: 'Organisations',
        component: () => import('../views/admin/Organisations.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: () => import('../views/user/Subscription.vue'),
        meta: { access: ['member', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'goal',
        name: 'Goal',
        component: () => import('../views/user/Goal.vue'),
        meta: { access: ['member'] },
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('../views/reports/Reports.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'foods',
        name: 'Foods',
        component: () => import('../views/foods/Foods.vue'),
        meta: { access: ['admin', 'dietitian', 'nutritionist', 'naturopath'] },
      },
      {
        path: '/promo-ebook',
        name: 'PromoEbook',
        component: () => import('../views/PromoEbook.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'], showDrawer: true },
      },
      {
        path: '/meal-plans',
        name: 'MealPlans',
        component: () => import('../views/meals/Meals.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'], showDrawer: true },
      },
      {
        path: '/recipe-plans',
        name: 'RecipePlans',
        component: () => import('../views/meals/Meals.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'], showDrawer: true },
      },
      {
        path: '/recipe-stats',
        name: 'RecipeStats',
        component: () => import('../views/recipe/RecipeStats.vue'),
        meta: { access: ['admin', 'dietitian', 'nutritionist', 'naturopath'], showDrawer: true },
      },
      {
        path: 'marketplace',
        name: 'Marketplace',
        component: () => import('../views/marketplace/Marketplace.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'] },
      },
      {
        path: 'excel',
        name: 'Excel',
        component: () => import('../views/excel/Excel.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../views/notifications/notification.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import('../views/Support.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'] },
      },
      {
        path: '/requests',
        name: 'MealplanRequests',
        component: () => import('../views/mealplanreq/MealplanRequests.vue'),
        meta: { access: ['admin'] },
      },
      {
        path: '/clients',
        name: 'Clients',
        component: () => import('../views/Clients.vue'),
        meta: { access: ['admin', 'dietitian', 'personal_trainer', 'nutritionist', 'naturopath'] },
      },
    ]
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue'),
    meta: { guestOnly: true },
  },
  {
    path: '/dietitian-registration',
    name: 'DietitianRegistration',
    component: () => import('../views/auth/DietitianRegistration.vue'),
    meta: { guestOnly: true },
  },
  {
    path: '/pt-registration',
    name: 'PtRegistration',
    component: () => import('../views/auth/PtRegistration.vue'),
    meta: { guestOnly: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/confirm-reset-password',
    name: 'ConfirmResetPassword',
    component: () => import('../views/auth/ConfirmResetPassword.vue'),
    meta: { guestOnly: true }
  },
  {
    path: '/download-recipe',
    name: 'DownloadRecipe',
    component: () => import('../views/recipe/DownloadRecipe.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/download-mealplan',
    name: 'DownloadMealplan',
    component: () => import('../views/meals/components/DownloadMealplan.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/public/PrivacyPolicy.vue'),
  },
  {
    path: '/ebook/:name/:client',
    name: 'Ebook',
    component: () => import('../views/Ebook.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/promoebookpage/:name/:client',
    name: 'PromoEbookPage',
    component: () => import('../views/PromoEbookPage.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/mealplanebook/:name/:client',
    name: 'MealplanEbook',
    component: () => import('../views/MealplanEbook.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/form/:name',
    name: 'Form',
    component: () => import('../views/Form.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/recipeform/:name',
    name: 'RecipeForm',
    component: () => import('../views/RecipeForm.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/promoform/:name',
    name: 'PromoForm',
    component: () => import('../views/PromoForm.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () { return { x: 0, y: 0 } },
  routes
})

router.beforeEach((to, from, next) => {
 
  // Check if on mobile then closes the drawer every
  // click on the menu
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)){
    store.commit('user/updateDrawer', false)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (firebase.auth().currentUser && to.matched.some(record => record.meta.access)) {

      to.matched.some(record => {
        var access = record.meta.access

        if (store.state.user) {
          var checkUser = setInterval(function () {
            if (store.state.user.user && access) {
              let role = store.state.user.user.role
              access.includes(role) ? next() : next({ name: 'Home' })
              clearInterval(checkUser)
            }
          }, 100)
        }
      })

    }
    else {
      !firebase.auth().currentUser ? next({ name: 'Login' }) : next()
    }
  }
  else if (to.matched.some(record => record.meta.guestOnly)) {
    firebase.auth().currentUser ? next({ name: 'Home' }) : next()
  } else {
    next()
  }
})

export default router
