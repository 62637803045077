import Vue from 'vue'
import db from '@/firebase/init'
import { messaging } from '@/firebase/init'
import { trim } from 'lodash'

/*------------------------------------------------------------------------------
 * GLOBAL CLASSES
 *----------------------------------------------------------------------------*/
Vue.prototype.$classes = {
  cardTitle: 'px-5 blue-grey lighten-5 justify-space-between align-center'
}

/*------------------------------------------------------------------------------
 * FORMAT DOC FROM FIRESTORE
 *----------------------------------------------------------------------------*/
Vue.prototype.$formatData = (payload) => {
   let data = payload.data()
   data.id = payload.id
   data.ref = payload.ref
   return data
}

/*------------------------------------------------------------------------------
 * GET NUTRIENTS FUNCTION
 *----------------------------------------------------------------------------*/
Vue.prototype.$calcNutrients = (data, ingredients) => {
   let nutrientsData = {
      weight: 0,
      energy: 0,
      protein: 0,
      fat: 0,
      carbohydrate: 0,
      sugar: 0,
      fibre: 0,
      sodium: 0
  }

  let nKeys = {
    energy: 'EnergyDF_kJ',
    protein: 'Protein_g',
    fat: 'Total_fat_g',
    carbohydrate: 'Carbohydrate_available_g',
    sugar: 'Sugars_g',
    fibre: 'Dietary_fibre_g',
    sodium: 'Sodium_mg',
  }

  if (data.ingredients.length) {
    data.ingredients.forEach(ing => {
      Object.keys(nKeys).forEach(key => {
        let ingredient = ingredients.find(igd => igd.id == ing.id)

        if (ingredient) {
          let nutrient = ingredient[nKeys[key]]
          
          let unitMeasure = 0
          let result = 0

          if (['Kg', 'L'].includes(ing.measure)) unitMeasure = (parseInt(ing.amount) * 1000) / 100
          else unitMeasure = parseInt(ing.amount) / 100

          result = (parseFloat(nutrient) * parseFloat(unitMeasure))
          nutrientsData[key] += result
        }
      })
    })
  }

  return nutrientsData
}

/*------------------------------------------------------------------------------
 * CALCULATE NUTRIENTS PER 100 GRAM
 *----------------------------------------------------------------------------*/
Vue.prototype.$calcNutrientsPerHundredGram = (data, ingredients) => {
   let nutrientsData = {
      weight: 0,
      energy: 0,
      protein: 0,
      fat: 0,
      carbohydrate: 0,
      sugar: 0,
      fibre: 0,
      sodium: 0
  }

  let nKeys = {
    energy: 'EnergyDF_kJ',
    protein: 'Protein_g',
    fat: 'Total_fat_g',
    carbohydrate: 'Carbohydrate_available_g',
    sugar: 'Sugars_g',
    fibre: 'Dietary_fibre_g',
    sodium: 'Sodium_mg',
  }

  if (data.ingredients.length) {
    data.ingredients.forEach(ing => {
      Object.keys(nKeys).forEach(key => {
        let ingredient = ingredients.find(igd => igd.id == ing.id)

        if (ingredient) {
          let nutrient = ingredient[nKeys[key]]
          let result = parseFloat(nutrient)

          if (result) nutrientsData[key] += result
        }
      })
    })
  }

  return nutrientsData
}

/*------------------------------------------------------------------------------
 * GET RECIPE INGREDIENTS
 *----------------------------------------------------------------------------*/
Vue.prototype.$getNutrients = async (recipe) => {
  let ingredients = []

  let nutrientsData = {
    weight: 0,
    energy: 0,
    protein: 0,
    fat: 0,
    carbohydrate: 0,
    sugar: 0,
    fibre: 0,
    sodium: 0
  }
  
  let nutrientsDataPerHundred = {
    weight: 0,
    energy: 0,
    protein: 0,
    fat: 0,
    carbohydrate: 0,
    sugar: 0,
    fibre: 0,
    sodium: 0
  }

  await recipe.ref
  .collection('ingredients')
  .get()
  .then(async (snapshot) => {
    if (snapshot.size) {
      snapshot.forEach(async doc => {
        await db.collection('foods').doc(doc.data().food).get()
        .then(async (foodDoc) => {
          if (foodDoc.exists) {
            let ingredient = foodDoc.data()

            if (doc.data().portion == 'gram') {
              ingredient.portion = {
                name: 'gram',
                unit: 'g',
                weight: 1
              }
              ingredient.quantity = doc.data().quantity
              ingredients.push(ingredient)
            }
            else {
              await foodDoc.ref.collection('portions')
              .doc(doc.data().portion)
              .get()
              .then(portionDoc => {
                if (doc.exists) {
                  ingredient.portion = portionDoc.data()
                  ingredient.quantity = doc.data().quantity
                  ingredients.push(ingredient)
                }
              })
            }
          }
        })
      })
    }
  })

  return { nutrientsData, nutrientsDataPerHundred, ingredients }
  
}

Vue.prototype.$filterPortion = (string) => {
  if (string) {
    let firstChar = string.substring(0, 1)
    let portion = isNaN(firstChar) ? string : string.substring(1, string.length)
  
    return trim(portion)
  }
  else return string
}

Vue.prototype.$messaging = messaging

Vue.prototype.$window = window