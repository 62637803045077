import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  reports: [],
  status: {
    adding: false,
    getting: false,
    closing: [],
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  resetData(state) {
    state.data = {}
  },

  setReports(state, payload) {
    state.reports = []

    if (payload.size) {
      payload.forEach(doc => {
        if (!state.reports.find(r => r.id == doc.id)) {
          let report = doc.data()
          report.id = doc.id
          report.ref = doc.ref
          state.reports.push(report)
        }
      })
    }
  },

  addToClosing(state, id) {
    state.status.closing.push(id)
  },
  
  removeFromClosing(state, id) {
    state.status.closing.splice(id, 1)
  },

  removeReport(state, payload) {
    let report = state.reports.find(r => r.id == payload.id)
    state.reports.splice(state.reports.indexOf(report), 1)
    state.status.closing.splice(report.id, 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * ADD REPORT
   *----------------------------------------------------------------------------*/
  async addReport({ state, commit, dispatch, rootState }, { recipe, createdBy, recipeName }) {
    commit('updateStatus', { adding: true })
    const { firstName } = rootState.user.user
    let user = firebase.auth().currentUser
    
    state.data.created = firebase.firestore.Timestamp.now()
    state.data.user = user.uid
    state.data.status = 'pending'
    state.data.recipe = recipe

    await db.collection('reports')
    .add(state.data)
    .then(() => {
      commit('updateStatus', { adding: false })

      // Get the user who created
      // the recipe
      db.collection('users')
      .doc(createdBy)
      .get()
      .then(doc => {
        if (doc.exists) {
          const { role, email } = doc.data()
          // Send an email if he/she is a Dietitian/Nutritionist/Naturopath
          if(role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
            let data = {
              recipient: email,
              subject: 'Recipe Reported',
              message: `Your recipe "<strong>${recipeName}</strong>" has been reported. We will notify you with the updates as soon as we've resolved it.`
            }

            dispatch('mail/notify', data, { root: true })
          }
          // Email to Chris
          dispatch('mail/reported', { firstName , mealType: 'Recipe', mealName: recipeName }, { root: true })
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
      dispatch('showSuccess', 'Report sent', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET REPORTS
   *----------------------------------------------------------------------------*/
  getReports({ commit, dispatch }) {
    commit('updateStatus', { getting: true })
    
    db.collection('reports')
    .where('status', '==', 'pending')
    .orderBy('created', 'desc')
    .get().then(snapshot => {
      if (snapshot.size) {
        commit('setReports', snapshot)

        snapshot.forEach(doc => {
          dispatch('members/getMember', doc.data().user, { root: true })
          dispatch('recipes/getRecipe', { id: doc.data().recipe }, { root: true })
        })
      }
      
      commit('updateStatus', { getting: false })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
    })
  },

  /*------------------------------------------------------------------------------
   * CLOSE REPORT
   *----------------------------------------------------------------------------*/
  closeReport({ commit, dispatch }, report) {
    commit('addToClosing', report.id)
    
    report.ref
    .update({ status: 'closed' })
    .then(() => {
      dispatch('showSuccess', 'Report closed', { root: true })
      commit('removeReport', report)
    })
    .catch(error => {
      console.log(error.message)
      commit('removeFromClosing', report.id)
      dispatch('showError', error.message, { root: true })
    })
  }
}



export default {
  namespaced: true,
  state,
  mutations,
  actions
}

