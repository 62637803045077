import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'

Vue.filter('sliceText', text => {
  let txt = text.split("")
  return `${txt[0]}.`
})

Vue.filter('formatDate', date => {
  let converted
  
  try {
    converted = date.toDate()
  }
  catch {
    converted = date
  }

  return moment(converted).format('MMM D, YYYY')
})

Vue.filter('completeDate', date => {
  return moment(date).format("ddd, MMM D")
})

Vue.filter('fromNow', date => {
  return moment(date).fromNow()
})

Vue.filter('amount', amount => {
  return parseFloat(amount).toFixed(2)
})

Vue.filter('capitalize', text => {
  return _.capitalize(text)
})

Vue.filter('startCase', text => {
  return _.startCase(text)
})

Vue.filter('numberFormat', number => {
  return new Intl.NumberFormat().format(Math.round(number))
})

Vue.filter('secondsToDate', seconds => {
  return new Date(seconds * 1000)
})

Vue.filter('formatDate2', date => {
  if (!date) return null
  else if (typeof date == 'object') {
    return moment(date.toDate()).format('D/MM/YYYY')
  }
  else {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  }
})

Vue.filter('percent', value => {
  return `${Math.round(value * 100)}%`
})

Vue.filter('kjToCal', value => {
  return Math.round(value / 4.18)
})