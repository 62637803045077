import firebase from 'firebase'
import Vue from 'vue'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
    ratings: [],
    data: {},
    status: {
      getting: false,
      creating: false,
      deleting: false,
    }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  creatingState(state, bol) {
    state.status.creating = bol
  },

  setData(state, payload) {
    state.data = payload
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  setRatings(state, payload) {
    state.ratings = []
    
    payload.forEach(doc => {
      let data = Vue.prototype.$formatData(doc)
      state.ratings.push(data)
    })
  },

  updateRating(state, payload) {
    let rating = state.ratings.find(r => r.id == payload.id)

    if (rating) Vue.set(state.ratings, state.ratings.indexOf(rating), payload)
    else state.ratings.push(rating)  
  },

  removeRating(state, payload) {
    state.ratings.splice(state.ratings.indexOf(payload), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET RECIPE RATINGS
   *----------------------------------------------------------------------------*/
  async getRatings({ commit, dispatch }, recipe) {
    commit('gettingState', true)
    
    await db.collection('recipes')
    .doc(recipe.id)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setRatings', snapshot)
        commit('gettingState', false)
      }
      else {
        commit('setRatings', [])
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD RATING
   *----------------------------------------------------------------------------*/
  addRating({ state, commit, dispatch }, recipe) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    
    recipe.ref.collection('ratings')
    .where('user', '==', user.uid)
    .limit(1).get()
    .then(snapshot => {
      let ref

      let data = {
        user: user.uid,
        rating: state.data.rating,
        comment: state.data.comment,
        createdAt: Date.now()
      }

      if (snapshot.size) {
        let doc = snapshot.docs[0]
        ref = doc.ref
      }
      else {
        ref = recipe.ref.collection('ratings').doc()
      }

      ref.set(data)
      .then(() => {
        commit('creatingState', false)
        dispatch('showSuccess', 'Rating saved', { root: true })
        let newData = data
        newData.ref = ref
        newData.id = ref.id
        commit('setData', newData)
        commit('updateRating', newData)
      })
    })
    .catch(error =>{
      commit('creatingState', false)
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE RATING
   *----------------------------------------------------------------------------*/
  delete({ state, commit, dispatch }) {
    commit('deletingState', true)
    let rating = Object.assign({}, state.data)
    
    state.data.ref.delete()
    .then(() => {
      dispatch('showSuccess', 'Rate deleted', {root: true})
      commit('deletingState', false)
      commit('removeRating', rating)
      commit('setData', {})
    })
    .catch(error => {
      dispatch('showError', error.message, {root: true})
      commit('deletingState', false)
      console.log(error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}