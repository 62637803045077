import db from '@/firebase/init'
import firebase from 'firebase'
// import router from '@/router'
import { userIndex } from '@/algolia/init'
import _ from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
  return {
    data: {},
    status: {
      error: null,
      adding: false,
      updating: false,
      creating: false,
    }
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  setInviteDate(state, email) {
    state.data.email = email
    state.data.role = 'dietitian'
    state.data.status = 'pending'
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setDataFieldValue(state, payload) {
    Vue.set(state.data, payload.field, payload.value)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  /*------------------------------------------------------------------------------
   * REGISTER USER
   *----------------------------------------------------------------------------*/
  async registerUser({ commit, state, dispatch }) {
    commit('updateStatus', { adding: true })
    commit('updateStatus', { error: null })

    let data = Object.assign({}, state.data)
    data.role = data.role || 'member'
    // data.status = data.status || 'active'
    data.registeredAt = firebase.firestore.Timestamp.now()
    data.updatedAt = firebase.firestore.Timestamp.now()
    data.birthDate = firebase.firestore.Timestamp.fromDate(new Date(data.birthDate)),
    data.freeTrialPopUp = true
      data.savedRecipes = []
    data = _.omit(data, ['password', 'repeatPassword'])

    if (data?.isStudent) {
      data.downloadCredits = -1
      data.mealSlots = -1
      data.customLogo = data.organisation.logo
      data.customLogoUrl = data.organisation.logo
      data.brandColor = data.organisation.hexCode
    }

    // FREE TRIAL
    // data.downloadCredits = -1
    // data.mealSlots = -1
    // data.freeTrial = true
    // data.expiredPopUp = true
    data.status = 'active'

    await firebase.auth()
      .createUserWithEmailAndPassword(state.data.email, state.data.password)
      .then(async (response) => {
        await db.collection('users')
          .doc(response.user.uid)
          .set(_.omitBy(data, _.isUndefined))
          .then(() => {
            // if (data.role == 'member') router.push({ name: 'Goal' })
            // else router.push({ name: 'MealPlans' })

            // ADD TO ALGOLIA
            let objectData = {
              fullname: `${data.firstName} ${data.lastName}`,
              email: data.email,
              objectID: response.user.uid
            }

            userIndex.saveObject(objectData)

            // SEND EMAIL
            dispatch('mail/registration', data, { root: true })

            // SEND WELCOME MESSAGE
            // dispatch('mail/welcome', data, { root: true })
          })
          .catch(error => {
            dispatch('showError', error.message, { root: true })
            const deleteFunc = firebase.functions().httpsCallable('forceDeleteAuth')
            deleteFunc({ userId: response.user.uid })
              .catch(() => { console.log('Auth deleted') })
          })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('updateStatus', { error: error.message })
      })
      .finally(() => {
        commit('updateStatus', { adding: false })
      })
  },

  /*------------------------------------------------------------------------------
   * CONFIRM RESET PASSWORD
   *----------------------------------------------------------------------------*/
  confirmResetPassword({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('updateStatus', { updating: true })

      firebase.auth()
        .confirmPasswordReset(data.code, data.password)
        .then(() => {
          commit('updateStatus', { updating: false })
          resolve('Password reset successful')
        })
        .catch(error => {
          commit('updateStatus', { updating: false })
          dispatch('showError', error.message, { root: true })
          reject(error.message)
        })
    })

  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
