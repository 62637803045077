import Vue from 'vue'

Vue.component('preloader',
  () => import('@/components/Preloader')
)

Vue.component('circular',
  () => import('@/components/PreloaderCircular')
)

Vue.component('UserPhoto',
  () => import('@/components/UserPhoto')
)

Vue.component('status',
  () => import('@/components/StatusBadge')
)

Vue.component('ConfirmDelete',
  () => import('@/components/ConfirmDelete')
)

Vue.component('search',
  () => import('@/components/Search')
)

Vue.component('loading',
  () => import('@/components/LoadingDialog')
)

Vue.component('back',
  () => import('@/components/Back')
)

Vue.component('CloseButton',
  () => import('@/components/CloseButton')
)
