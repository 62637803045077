import { userIndex } from '@/algolia/init'
import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  members: [],
  allUsers: [],
  dietitians: [],
  naturopaths: [],
  noneExisting: [],
  nutritionists: [],
  searchResults: [],
  lastVisible: null,
  pendingMembers: [],
  status: {
    limit: 20,
    getting: false,
    deleting: false,
    inviting: false,
    searching: false,
    firstLoad: false,
    gettingAll: false,
    loadingMore: false,
    updatingRole: false,
    sendingInvite: false,
    pendingFirstLoad: false,
    lastPendingVisible: false,
    gettingPendingMembers: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  canAddRecipeMembers: (state) => {
    return state.members.filter(member => {
      return member.role == 'admin' || member.role == 'dietitian' || member.role == 'nutritionist' || member.role == 'naturopath'
    })
  },

  getUser: (state) => (id) => {
    let member = state.members.find(member => member.id == id)

    if (member) {
      return `${member.firstName} ${member.lastName.split("")[0]}.`
    }
    else if (state.noneExisting.includes(id)) {
      return 'non-existent'
    }
  },

  userData: (state) => (id) => {
    return state.members.find(m => m.id == id) || {}
  },

  getRole: (state) => (id) => {
    let member = state.members.find(member => member.id == id)

    if (member) {
      return member.role
    }

    else if (state.noneExisting.includes(id)) {
      return 'non-existent'
    }
  },
  getEmail: (state) => (id) => {
    let member = state.members.find(member => member.id == id)

    if (member) {
      return member.email
    }

    else if (state.noneExisting.includes(id)) {
      return 'non-existent'
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setMembers(state, payload){
    payload.forEach(doc => {
      let data = Vue.prototype.$formatData(doc)
      
      if (!state.members.find(m => m.id == doc.id))
        state.members.push(data)
    })

    state.status.getting = false
    state.status.loadingMore = false
    state.status.firstLoad = true
  },

  setGettingState(state, bol) {
    state.status.getting = bol
  },

  insertMember(state, payload) {
    let data = Vue.prototype.$formatData(payload)

    if (!state.members.find(member => member.id == data.id)) {
      state.members.push(data)
    }
  },

  insertNoneExisting(state, id) {
    state.noneExisting.push(id)
  },

  setLastVisible(state, payload) {
    state.lastVisible = payload
  },

  loadingMoreState(state, bol) {
    state.status.loadingMore = bol
  },

  emptyMembers(state) {
    state.lastVisible = null
    state.members = []
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeMember(state, payload) {
    state.members.splice(state.members.indexOf(payload), 1)
    state.pendingMembers.splice(state.pendingMembers.indexOf(payload), 1)
    state.searchResults.splice(state.searchResults.indexOf(payload), 1)
  },

  searchingState(state, bol) {
    state.status.searching = bol
  },

  insertSearchResult(state, payload) {
    let data
    
    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.searchResults.push(data)
  },

  emptySearchResults(state) {
    state.searchResults = []
  },

  updatingRoleState(state, bol) {
    state.status.updatingRole = bol
  },

  updateMemberRole(state, payload) {
    let user = payload.user
    let role = payload.role
    user.role = role
    state.status.updatingRole = false
  },

  sendingInviteState(state, bol) {
    state.status.sendingInvite = bol
  },

  invitingState(state, bol) {
    state.status.inviting = bol
  },
  
  activeStatus(state, member) {
    member.status = 'active'
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setPendingMembers(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        if (!state.pendingMembers.find(m => m.id == doc.id)) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          state.pendingMembers.push(data)
        }
      })
    }

    state.status.gettingPendingMembers = false
  },

  setDietitians(state, payload) {
    state.dietitians = []
    payload.forEach(dietitian => {
      let data = Vue.prototype.$formatData(dietitian)
      state.dietitians.push(data)
    })
  },

  setNutritionists(state, payload) {
    state.nutritionists = []
    payload.forEach(nutritionist => {
      let data = Vue.prototype.$formatData(nutritionist)
      state.nutritionists.push(data)
    })
  },

  setNaturoPaths(state, payload) {
    state.naturopaths = []
    payload.forEach(naturopath => {
      let data = Vue.prototype.$formatData(naturopath)
      state.naturopaths.push(data)
    })
  },

  setGettingAll(state, payload) {
    state.gettingAll = payload
  },
  setAllUsers(state, payload) {
    state.allUsers = []
    payload.forEach(users => {
      let data = Vue.prototype.$formatData(users)
      state.allUsers.push(data)
    })
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET MEMBERS
   *----------------------------------------------------------------------------*/
  getMembers({ state, commit, dispatch }) {
    if (!state.lastVisible) commit('updateStatus', { getting: true })
    else commit('updateStatus', { loadingMore: true })

    let query = db.collection('users')
    .where('status', '==', 'active')
    .orderBy('registeredAt', 'desc')

    if (state.lastVisible)
      query = query.startAfter(state.lastVisible)
    
    query.limit(state.status.limit).get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1])
        commit('setMembers', snapshot)
      }
      else {
        commit('setGettingState', false)
        commit('loadingMoreState', false)
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('setGettingState', false)
      commit('loadingMoreState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET PENDING MEMBERS
   * @params none
   *----------------------------------------------------------------------------*/
  getPendingMembers({ state, commit }) {
    commit('updateStatus', { gettingPendingMembers: true })
    commit('updateStatus', { pendingFirstLoad: true })
    
    let query = db.collection('users')
    .where('status', '==', 'pending')
    
    if (state.status.lastPendingVisible)
      query = query.startAfter(state.status.lastPendingVisible)

    query.limit(20)
    .onSnapshot((snapshot) => {
      commit('setPendingMembers', snapshot)

      // if (snapshot.size) {
      //   let lastVisible = snapshot.docs[snapshot.docs.length - 1]
      //   commit('updateStatus', { lastPendingVisible: lastVisible})
      // }
    })
  },

  /*------------------------------------------------------------------------------
   * GET MEMBER
   *----------------------------------------------------------------------------*/
  getMember({ commit, state }, id) {
    if (!state.members.find(member => member.id == id)) {
      return new Promise((resolve, reject) => {
        db.collection('users')
        .doc(id).get()
        .then(doc => {
          if (doc.exists) {
            commit('insertMember', doc)
            resolve(doc)
          }
          else {
            commit('insertNoneExisting', id)
            reject('user not found')
          }
        })
        .catch(error => {
          console.log(error.message)
          reject(error.message)
        })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE MEMBER
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, member) {
    commit('deletingState', true)
    
    await member.ref.delete()
    .then(async () => {
      // DELETE RELATED DOCS
      await Promise.all([
        dispatch('goal/deleteGoalByUser', member.id, { root: true }),
        dispatch('mealplans/deleteMealsByUser', member.id, { root: true }),
        dispatch('subscriptions/deleteSubscription', member.id, { root: true }),
      ])
      .then(() => {
        commit('deletingState', false)
        commit('removeMember', member)
        dispatch('showSuccess', 'User successfully deleted.', { root: true })
      })
    })
    .catch(error => {
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH MEMBERS
   *----------------------------------------------------------------------------*/
  async searchMembers({ state, commit, dispatch }, term) {
    commit('searchingState', true)
    commit('emptySearchResults')

    await userIndex.search(term, {
      attributesToRetrieve: ['firstName', 'lastName', 'objectID']
    })
    .then(async ({ hits }) => {
      if (hits.length) {
        await hits.forEach(async (hit) => {
          let member = state.members.find(member => member.id == hit.objectID)
  
          if (member) {
            commit('insertSearchResult', member)
          }
          else {
            await db.collection('users')
            .doc(hit.objectID)
            .get()
            .then(doc => {
              if (doc.exists) commit('insertSearchResult', doc)
            })
            .catch(error => {
              dispatch('showError', error.message, { root: true })
              console.log(error.message)
            })
          }
        })
      }
      else {
        dispatch('showSuccess', 'User not found.', { root: true })
      }
      
      commit('searchingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE USER ROLE
   *----------------------------------------------------------------------------*/
  async updateRole({ commit, dispatch }, data) {
    commit('updatingRoleState', true)

    let dataToUpdate = data.user?.isStudent ? { secondaryRole: data.role } : { role: data.role }
    
    await data.user.ref.update(dataToUpdate)
    .then(() => {
      let toUpdate = data.user?.isStudent ? { user: data.user, secondaryRole: data.role} : { user: data.user, role: data.role}

      commit('updateMemberRole', toUpdate)

      dispatch('showSuccess', 'User role updated.', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updatingRoleState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SEND EMAIL
   *----------------------------------------------------------------------------*/
  async sendInvitation({ commit, dispatch }, email) {
    commit('invitingState', true)
    let created = firebase.firestore.Timestamp.now()
    let error = false

    await db.collection('users')
    .where('email', '==', email)
    .limit(1).get()
    .then(snapshot => {
      if (!snapshot.size) {
        return db.collection('invites')
        .add({ email, role: 'dietitian', created })
        .then((docRef) => {
          console.log(docRef)
          commit('invitingState', false)
          dispatch('showSuccess', 'Invitation sent', { root: true })
    
          let data = {
            recipient: email,
            subject: 'Mealzee Dietitian Invitation',
            message: `
              Hi, <br/><br/>
              You are invited as a dietitian.<br/>
              Click <a href="${window.location.origin}/register?invite=${docRef.id}" target="_blank">here</a> to accept the invitation.
            `
          }
    
          dispatch('mail/notify', data, { root: true })
        })
      }
      else {
        error = 'User already exists'
        commit('invitingState', false)
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('invitingState', false)
      dispatch('showError', error.message, { root: true })
    })
    
    return error
  },

  /*------------------------------------------------------------------------------
   * APPROVE USER
   *----------------------------------------------------------------------------*/
  approveUser({ commit, dispatch }, member) {
    member.ref
    .update({ status: 'active' })
    .then(() => {
      dispatch('showSuccess', 'Account approved', { root: true })
      commit('activeStatus', member)

      dispatch('mail/notify', {
        recipient: member.email,
        subject: 'MEALZEE Account Approved',
        message: `
          Hi ${member.firstName},<br/>
          <br/>
          Congratulations! Your membership for MEALZEE has been approved. <br/>
          Click <a href="https://app.mealzee.com/">here</a> to start with MEALZEE and help your clients achieve amazing results.
        `
      }, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ALL DIETITIANS
   *----------------------------------------------------------------------------*/
  getAllDietitians({ commit, dispatch }) {
    db.collection('users')
    .where('role', '==', 'dietitian')
    .orderBy('firstName', 'asc')
    .get()
    .then(snapshot => {
      if(snapshot.size){
        commit('setDietitians', snapshot)
      }
    })
    .catch((error) => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },
  getAllNutritionists({ commit, dispatch }) {
    db.collection('users')
    .where('role', '==', 'nutritionist')
    .orderBy('firstName', 'asc')
    .get()
    .then(snapshot => {
      if(snapshot.size){
        commit('setNutritionists', snapshot)
      }
    })
    .catch((error) => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },
  getAllNaturopaths({ commit, dispatch }) {
    db.collection('users')
    .where('role', '==', 'naturopath')
    .orderBy('firstName', 'asc')
    .get()
    .then(snapshot => {
      if(snapshot.size){
        commit('setNaturoPaths', snapshot)
      }
    })
    .catch((error) => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  async getAllUsers({ commit, dispatch }) {
    try {
      commit('setGettingAll', true)
      const snapshot = await db.collection('users').where('status', '==', 'active').get()

      if(snapshot.size) {
        commit('setAllUsers', snapshot)
      }
    } catch(error) {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    } finally {
      commit('setGettingAll', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
