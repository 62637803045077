import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  categories: [],
  data: {},
  status: {
    getting: false,
    firstLoad: false,
    adding: false,
    error: null,
    deleting: false,
    updating: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  categoryName: (state) => (id) => {
    let category = state.categories.find(category => category.id == id)
    return category ? category.name : false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setGettingState(state, bol) {
    state.status.getting = bol
  },

  setCategories(state, payload) {
    state.categories = []

    payload.forEach(doc => {
      let data = doc.data()
      data.id = doc.id
      data.ref = doc.ref
      state.categories.push(data)
    })

    state.status.getting = false
    state.status.firstLoad = true
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertCategory(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.categories.push(data)
    state.data = {}

    state.status.adding = false
  },

  setDeletingState(state, bol) {
    state.status.deleting = bol
  },

  removeCategory(state, category) {
    state.categories.splice(state.categories.indexOf(category), 1)
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET CATEGORIES
   *----------------------------------------------------------------------------*/
  getCategories({ commit, dispatch }) {
    commit('setGettingState', true)

    db.collection('categories').get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setCategories', snapshot)
      }
      else {
        commit('setGettingState', false)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET CATEGORY
   *----------------------------------------------------------------------------*/
  getCategory({ commit, dispatch }, id) {
    db.collection('categories').doc(id).get()
    .then(doc => {
      commit('insertCategory', doc)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ADD CATEGORIES
   *----------------------------------------------------------------------------*/
  addCategory({ commit, state, dispatch }) {
    commit('setAddingState', true)

    let user = firebase.auth().currentUser
    let data = state.data
    data.createdAt = Date.now()
    data.updatedAt = Date.now()
    data.addedBy = user.uid
    data.active = true

    db.collection('categories').add(data)
    .then((docRef) => {
      dispatch('getCategory', docRef.id)
      dispatch('showSuccess', 'Successfully added food category.', { root: true })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CATEGORY
   *----------------------------------------------------------------------------*/
  async deleteCategory({ commit, dispatch }, category) {
    commit('setDeletingState', true)

    await category.ref.delete()
    .then(() => {
      commit('setDeletingState', false)
      commit('removeCategory', category)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setDeletingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE CATEGORY
   *----------------------------------------------------------------------------*/
  async updateCategory({ commit, dispatch }, category) {
    commit('setUpdatingState', true)
    if (state.status.error) commit('setError', null)

    await category.ref.update({ name: category.name, updatedAt: Date.now() })
    .then(() => {
      dispatch('showSuccess', 'Category successfully updated.', { root: true })
      commit('setUpdatingState', false)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setUpdatingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
