import Vue from 'vue'
import db from '@/firebase/init'
import { omit, mean } from 'lodash'

import { marketplaceIndex } from '@/algolia/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialState = () => {
    return {
        data: {},
        hits: [],
        tagValue: '',
        tagType: '',
        searchTerm: '',
        rating: 3.5,
        lastVisible: null,
        marketplacePlans: [],
        status: {
            limit: 0,
            error: null,
            adding: false,
            getting: false,
            deleting: false,
            firstLoad: false,
            inserting: false,
            searching: false,
            generating: false,
            ratingState: false,
            loadingMore: false,
            copyingPlan: false,
            deletingPlan: false,
        }
    }
}

const state = initialState()


/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
    resetState(state) {
        Object.assign(state, initialState())
    },
    insertPlan(state, payload) {
        let data

        try {
            data = Vue.prototype.$formatData(payload)
        }
        catch {
            data = payload
        }

        if (!state.marketplacePlans.find(p => p.id == data.id))
            state.marketplacePlans.push(data)
    },

    gettingState(state, bol) {
        state.status.getting = bol
    },

    setPlans(state, payload) {
        state.marketplacePlans = []

        payload.forEach(plan => {
            let data = Vue.prototype.$formatData(plan)
            state.marketplacePlans.push(data)
        })

        state.status.getting = false
    },

    addingState(state, bol) {
        state.status.adding = bol
    },

    setError(state, message) {
        state.status.error = message
    },

    insertingRecipe(state, bol) {
        state.status.inserting = bol
    },

    updateStatus(state, payload) {
        state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
    },

    setLastVisible(state, recipe) {
        state.lastVisible = recipe
    },

    setLoadingMoreState(state, bol) {
        state.status.loadingMore = bol
    },

    emptyRecipes(state) {
        state.marketplacePlans = []
    },

    setDeletingPlan(state, bol) {
        state.status.deletingPlan = bol
    },

    refreshMarketplacePlans(state, mealPlan) {
        state.marketplacePlans = state.marketplacePlans.filter(plan => plan.id !== mealPlan)
    },

    setCopyPlan(state, bol) {
        state.status.copyingPlan = bol
    },

    setSearchingState(state, bol) {
        state.status.searching = bol
    },
    setHits(state, payload) {
        state.hits = []

        if (payload.length) {
            payload.forEach(doc => {
                if (doc.exists) {
                    let plan = doc.data()
                    plan.id = doc.id
                    plan.ref = doc.ref

                    if (!state.hits.find(h => h.id == doc.id)) {
                        state.hits.push(plan)
                    }
                }
            })

        }
    },
    setClearSearch(state) {
        state.hits = []
    },

    setTagValue(state, val) {
        state.tagValue = val
    },

    setTagType(state, val) {
        state.tagType = val
    },

    clearOrderby(state) {
        state.tagValue = ''
        state.tagType = ''
        state.lastVisible = null
    },
    setSearchTerm(state, val) {
        state.searchTerm = val
    },
    setRatingState(state, val) {
        state.status.ratingState = val
    },
    setRatingsAdded(state, payload) {
        const currentPlan = state.marketplacePlans.find(plan => plan.id === payload.id)

        if (!currentPlan.ratings?.length)
            currentPlan.ratings = []

        currentPlan.ratings = [...currentPlan.ratings, payload]
    },
    setTotalRating(state, payload) {
        const currentPlan = state.marketplacePlans.find(plan => plan.id === payload.id)

        currentPlan['totalRatings'] = payload.totalRatings

    }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

    /*------------------------------------------------------------------------------
    * GET ALL MARKETPLACE PLANS 
    *----------------------------------------------------------------------------*/
    async getAllMarketplacePlans({ state, commit, dispatch }) {
        commit('updateStatus', { firstLoad: true })

        if (!state.lastVisible) commit('updateStatus', { getting: true })
        else commit('setLoadingMoreState', true)


        let query = db.collection('mealPlans')
            .where('addedToMarketplace', '==', true)

        if (state.tagType)
            query = query.where(state.tagType, 'array-contains', state.tagValue)

        if (state.lastVisible)
            query = query.startAfter(state.lastVisible)


        await query.get()
            .then(snapshot => {
                if (snapshot.size) {
                    commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1])
                    commit('setPlans', snapshot)
                    commit('setLoadingMoreState', false)
                }
                else {
                    if (state.marketplacePlans.length) {
                        dispatch('showError', 'No more recipes found', { root: true })
                    }
                    else {
                        commit('emptyRecipes')
                    }
                    commit('updateStatus', { getting: false })
                    commit('setLoadingMoreState', false)
                }
            })
            .catch(error => {
                console.log(error.message)
                commit('gettingState', false)
                dispatch('showError', error.message, { root: true })
            })
    },

    /*------------------------------------------------------------------------------
    * ADD TO MARKETPLACE
    *----------------------------------------------------------------------------*/

    async addToMarketplace({ state, commit, dispatch }, mealPlan) {
        commit('addingState', true)

        if (state.status.error) commit('setError', null)
        let hasError = false

        let data = state.data
        data.addedToMarketplace = true
        data.totalRatings = 0
        data.ratings = []
        data.updatedAt = Date.now()

        await db.collection('mealPlans').doc(mealPlan).update(omit(data, ['id', 'ref']))
            .then(() => {

                // ADD TO ALGOLIA
                let objectData = {
                    name: state.data.marketplaceName,
                    objectID: mealPlan
                }

                marketplaceIndex.saveObject(objectData)

                commit('addingState', false)
                dispatch('mealplans/addMealToMarketplace', mealPlan, { root: true })
                dispatch('showSuccess', 'Meal plan has been added to the Marketplace', { root: true })
            })
            .catch(error => {
                commit('addingState', false)
                commit('setError', error.message)
                hasError = true
            })

        return hasError
    },

    /*------------------------------------------------------------------------------
    * REMOVE FROM MARKETPLACE
    *----------------------------------------------------------------------------*/

    async removePlan({ state, commit, dispatch }, { mealPlan, role }) {
        commit('setDeletingPlan', true)

        if (state.status.error) commit('setError', null)
        let hasError = false


        await db.collection('mealPlans').doc(mealPlan).update({ 'addedToMarketplace': false })
            .then(async () => {

                // DELETE FROM ALGOLIA
                await marketplaceIndex.deleteObject(mealPlan)

                commit('setDeletingPlan', false)
                commit('refreshMarketplacePlans', mealPlan)

                if (role !== 'admin')
                    dispatch('mealplans/removedFromMarketplace', mealPlan, { root: true })

                dispatch('showSuccess', 'Meal plan has been removed the Marketplace', { root: true })
            })
            .catch(error => {
                commit('setDeletingPlan', false)
                commit('setError', error.message)
                hasError = true
            })

        return hasError
    },

    /*------------------------------------------------------------------------------
    * COPY MEAL PLAN
    *----------------------------------------------------------------------------*/

    /**
    * TODO: OPTIMIZE THIS CODE (TRANSFORM TO ASYNC/AWAIT)
    */

    copyPlan({ state, commit, dispatch, rootState }, mealPlan) {
        commit('setCopyPlan', true)

        const oldId = mealPlan.id
        let user = rootState.user.user

        if (state.status.error) commit('setError', null)

        // Create the new meal plan
        let data = Object.assign({}, mealPlan)
        data.user = user.id
        data.name = mealPlan.marketplaceName
        data.createdAt = Date.now()
        data.folderId = "2"

        delete data.marketplaceDesc
        delete data.id
        delete data.addedToMarketplace
        delete data.marketplaceName
        delete data.ref
        delete data.updatedAt
        delete data.linkDetails
        delete data.mealplanDescription
        delete data.mealplanLink

        console.log({ data })

        db.collection('mealPlans')
            .add(data)
            .then((docRef) => {

                const batch = db.batch()

                // Get All Meals for this meal plan
                db.collection('mealPlans')
                    .doc(oldId)
                    .collection('meals')
                    .get()
                    .then(snapshot => {
                        snapshot.docs.forEach(meal => {

                            let meals = db.collection('mealPlans')
                                .doc(docRef.id)
                                .collection('meals')
                                .doc()

                            let newMeal = Object.assign({}, meal.data())
                            newMeal.user = user.id
                            newMeal.createdAt = Date.now()

                            batch.set(meals, newMeal)
                        })

                        batch.commit()
                            .then(() => {

                                // Set the goals
                                db.collection('goals')
                                    .where('user', '==', oldId)
                                    .limit(1)
                                    .get()
                                    .then(snapshot => {
                                        if (snapshot.size) {
                                            let newGoal = Object.assign({}, snapshot.docs[0].data())

                                            newGoal.user = docRef.id

                                            db.collection('goals')
                                                .add(newGoal)
                                                .then(() => {
                                                    commit('setCopyPlan', false)
                                                    dispatch('showSuccess', 'Meal plan has been copied!', { root: true })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                    commit('setCopyPlan', false)
                                                    commit('setError', error.message)
                                                })
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        commit('setCopyPlan', false)
                                        commit('setError', error.message)
                                    })
                            })
                            .catch(error => {
                                console.log(error)
                                commit('setCopyPlan', false)
                                commit('setError', error.message)
                            })
                    })
                    .catch(error => {
                        console.log(error)
                        commit('setCopyPlan', false)
                        commit('setError', error.message)
                    })
            })
            .catch(error => {
                console.log(error)
                commit('setCopyPlan', false)
                commit('setError', error.message)
            })
    },

    /*------------------------------------------------------------------------------
    * SEARCH MEAL PLAN
    *----------------------------------------------------------------------------*/

    searchPlan({ state, commit, dispatch }, term) {

        commit('setSearchTerm', term)
        commit('setSearchingState', true)

        marketplaceIndex.search(term, {
            attributesToRetrieve: ['name', 'objectID']
        })
            .then(({ hits }) => {
                if (hits.length) {
                    let promises = []

                    hits.forEach(hit => {
                        promises.push(db.collection('mealPlans').doc(hit.objectID).get())
                    })

                    Promise.all(promises)
                        .then((docs) => {
                            commit('setSearchingState', false)
                            if (state?.tagType) {
                                const filteredDocs = docs.filter(doc => {
                                    if (doc.data()[state.tagType]?.length)
                                        if (doc.data()[state.tagType][0] === state.tagValue)
                                            return doc
                                })

                                if (!filteredDocs.length)
                                    dispatch('showError', 'No Meal Plan found', { root: true })

                                return commit('setHits', filteredDocs)
                            }
                            commit('setHits', docs)
                        }).catch(e => {
                            console.log(e)
                        })
                }
                else {
                    commit('setSearchingState', false)
                    dispatch('showError', 'No Meal Plan found.', { root: true })
                }
            })
    },

    /*------------------------------------------------------------------------------
    * SORT / LIMIT MEAL PLAN
    *----------------------------------------------------------------------------*/

    startSort({ state, dispatch }) {
        state.lastVisible = null

        if (state.searchTerm) {
            dispatch('searchPlan', state.searchTerm)
            return
        }

        dispatch('getAllMarketplacePlans')
    },

    /*------------------------------------------------------------------------------
    * CLEAR SORT / LIMIT MEAL PLAN
    *----------------------------------------------------------------------------*/

    clearSortMealPlan({ dispatch, commit }) {
        commit('clearOrderby')

        if (state.searchTerm) {
            dispatch('searchPlan', state.searchTerm)
            return
        }

        dispatch('getAllMarketplacePlans')
    },

    /*------------------------------------------------------------------------------
    * CLEAR SORT / LIMIT MEAL PLAN
    *----------------------------------------------------------------------------*/
    clearSearch({ dispatch, commit }) {
        commit('setClearSearch')

        if (state.tagType) {
            dispatch('getAllMarketplacePlans')
        }
    },

    /*------------------------------------------------------------------------------
    * RATE MEAL PLAN
    *----------------------------------------------------------------------------*/
    async ratePlan({ dispatch, commit, rootState }, { plan: { id }, rating }) {
        commit('setRatingState', true)
        let user = rootState.user.user

        const data = {}
        data.rating = rating
        data.ratedBy = user.id
        data.createdAt = Date.now()

        await db.collection('mealPlans').doc(id).update({
            ratings: firebase.firestore.FieldValue.arrayUnion(data)
        })
            .then(() => {

                // Get Total Ratings
                db.collection('mealPlans').doc(id).get()
                    .then((doc) => {
                        if (doc.exists) {
                            const allRatings = doc.data().ratings

                            // Calculate and Set Total Ratings
                            let numRatings = allRatings.map(rating => rating.rating)

                            const totalRatings = parseFloat(mean(numRatings).toFixed(2))

                            db.collection('mealPlans').doc(id).update({ totalRatings })
                                .then(() => {
                                    commit('setTotalRating', { id, totalRatings })
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    })

                dispatch('showSuccess', 'Thank you for your rating!', { root: true })

                commit('setRatingsAdded', { id, ...data })

                commit('setRatingState', false)
            })
            .catch(e => {
                console.log(e)
                commit('setRatingState', false)
                dispatch('showError', 'Something went wrong. Please try again', { root: true })
            })

    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}